import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {optimizeFilter} from '../../utils/filter.helper';
import {environment} from '../../../../environments/environment';
import {resToFileDownload} from '../../utils/file-export.helper';
import {User} from '../../models/users/users.model';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {
  private contactsUrl = environment.baseURL + environment.contacts;

  constructor(private http: HttpClient) {
  }

  getAll(params?: any): Promise<{ docs: User[], total: number }> {
    if (params) {
      optimizeFilter(params);
    }

    return this.http.get<{ docs: User[], total: number }>(this.contactsUrl, {params}).toPromise();
  }

  async exportContacts(params: any) {
    const res = await
      this.http.get(`${this.contactsUrl}/export`, {
        headers: new HttpHeaders({
          charset: 'UTF-8',
        }),
        params,
        observe: 'response',
        responseType: 'arraybuffer'
      }).toPromise();
    resToFileDownload(res, 'Kontaktpersonen.xlsx', 'excel');
  }
}
