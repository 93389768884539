<div class="ui padded grid">
  <div class="row">
    <div class="two wide column">
      <!--SIDEBAR-->
      <div class="ui vertical fluid tabular menu">
        <a class="item" routerLink="topics" routerLinkActive="active">
          Kategorien
        </a>
        <a class="item" routerLink="subjectareas" routerLinkActive="active">
          Fachrichtungen
        </a>
        <a class="item" routerLink="institutions" routerLinkActive="active">
          Institutionen
        </a>
        <a class="item" routerLink="articles" routerLinkActive="active">
          Artikel
        </a>
        <a class="item" routerLink="users" routerLinkActive="active">
          Nutzer
        </a>
        <a class="item" routerLink="contacts" routerLinkActive="active">
          Kontaktpersonen
        </a>
        <a class="item" routerLink="mailing" routerLinkActive="active">
          Admin-Mail
        </a>
      </div>
    </div>
    <div class="fourteen wide stretched column">
      <div class="ui segment">
        <!--CONTENT-->
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

